import React, { forwardRef, useState } from "react"
import moment from "moment"
import IconLabel from "./IconLabel"
import DatePicker from "react-datepicker"

const DateView = forwardRef(({ value, onClick }, ref) => {
  return (
    <IconLabel
      label={value}
      icon={"fa fa-calendar"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  )
})

const DateItem = ({ date, onSelect }) => {
  return (
    <DatePicker
      selected={date}
      onChange={date => {
        onSelect("date", moment(date).valueOf())
      }}
      customInput={<DateView />}
      dateFormat={"d MMM"}
    />
  )
}

export default DateItem
