import React, { forwardRef, useState } from "react"
import moment from "moment"
import IconLabel from "./IconLabel"
import DatePicker from "react-datepicker"

const TimeView = forwardRef(({ value, onClick }, ref) => {
  return (
    <IconLabel
      label={value}
      icon={"fa fa-clock-o"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  )
})

const TimeItem = ({ date, onSelect }) => {
  return (
    <DatePicker
      selected={date}
      onChange={date => {
        onSelect("time", moment(date).valueOf())
      }}
      customInput={<TimeView />}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
    />
  )
}

export default TimeItem
