import React from "react"

const SeatSelector = ({ seats, setSeats, labelStyle, disableEdit = false }) => {
  const increaseSeats = () => {
    setSeats(seats + 1)
  }

  const decreaseSeats = () => {
    setSeats(seats - 1)
  }
  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        {!disableEdit ? (
          <button
            onClick={decreaseSeats}
            className="d-flex align-items-center justify-content-center"
            style={{
              borderRadius: 5,
              width: 38,
              height: 38,
              backgroundColor: "#ffc7a733",
              border: "none",
            }}
          >
            <i className="fa fa-minus" aria-hidden="true"></i>
          </button>
        ) : null}
        <div
          style={{
            width: 32,
            marginHorizontal: 8,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <p className="mb-0">{seats}</p>
        </div>

        {!disableEdit ? (
          <button
            className="d-flex align-items-center justify-content-center"
            onClick={increaseSeats}
            style={{
              borderRadius: 5,
              width: 38,
              height: 38,
              backgroundColor: "#ffc7a733",
              border: "none",
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default SeatSelector
