import React, { useState } from "react"
import axios from "axios"
import { applyDiscountCode } from "../api/common"
const Applydiscount = props => {
  const [discountCode, setDiscountCode] = useState("")
  const [validating, setValidating] = useState(false)
  const [couponDiscount, setcouponDiscount] = useState(null)
  const [message, setMessage] = useState('')
  console.log(message)
  const handleValidate = async () => {
    setMessage(''); // Clear any previous messages
    setcouponDiscount(null); // Reset couponDiscount state
    setValidating(true); // Set validating to true
    const payload = {
      discountCode: discountCode,
      custId: props.user.uid, // Assuming props.user contains the user information with uid
    }

    try {
      const couponDetails = await applyDiscountCode({ payload });

      setcouponDiscount(couponDetails); // Set the response data to couponDiscount

      if (couponDetails.status === "success") {
        setMessage(couponDetails.message || 'Discount code applied successfully!'); // Set success message
        props.buttonClick(couponDetails.couponDetails.discountPercentage, discountCode);
      } else {
        setMessage(couponDetails.message || 'Error applying discount code.'); // Set error message from the response
        props.buttonClick(0, discountCode);
      }
    } catch (error) {
      // Capture and set the error message from the catch block
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message); // Set specific error message from server response
      } else {
        setMessage('An unexpected error occurred. Please try again.'); // Set a generic error message
      }
      setcouponDiscount({ status: "error" }); // Indicate error in discount state
    } finally {
      setValidating(false); // Set validating to false after the process completes
    }
  };
  return (
    <div className="row">
      <div className="col-md-9 col-8">
        <input
          className="form-control"
          style={{ marginBottom: "16px", borderRadius: "10px" }}
          placeholder="Discount Code"
          value={discountCode}
          onChange={e => {
            setDiscountCode(e.target.value)
          }}
        />
      </div>
      <div className="col-md-3 col-4">
        <button
          className="btn applyCoupon"
          onClick={handleValidate}
          disabled={discountCode.length < 3}
        >
          {validating ? "Validating..." : "Apply Now"}
        </button>
      </div>
      <div className="col-md-12">
        {couponDiscount &&
        couponDiscount.status === "success" &&
        couponDiscount.couponDetails &&
        couponDiscount.couponDetails.spaceTypes &&
        couponDiscount.couponDetails.spaceTypes.includes(props.spaceType) ? (
          <p style={{ color: "#16cc0d" }}>
            <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
            {parseFloat(
              couponDiscount.couponDetails.discountPercentage
            ).toFixed(1)}
            % Discount Applied
          </p>
        ) : null}
        {couponDiscount && couponDiscount.status != "success" && message ? (
          <p style={{ color: "#ad002d" }}>
            <i class="fa fa-times-circle" aria-hidden="true"></i>{message}
          </p>
        ) : null}
      </div>
      <br />
      <br />
    </div>
  )
}

export default Applydiscount